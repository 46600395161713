import React from 'react';
import { Grid, Card, CardContent, Typography } from '@material-ui/core';

// color palette:
// rgba(131, 155, 151, .8)
// rgba(198, 180, 151, .8)
// rgba(52, 98, 108, .8)

const CoreSkills = () => {
  return (
    <Card style={{ width: '320px' }} elevation={0}>
      <CardContent>
        <Card
          style={{
            borderRadius: 0,
            marginBottom: '5px',
            boxShadow: '1px 1px 1px 1px rgba(52, 98, 108, .8)',
          }}
        >
          <CardContent style={{ paddingBottom: '0px' }}>
            <Typography variant='h5' component='h2'>
              Data
            </Typography>
            <Grid
              container
              direction='row'
              justify='space-between'
              alignItems='center'
            >
              <Grid item>
                <Typography
                  variant='body2'
                  color='textSecondary'
                  component='span'
                >
                  <ul>
                    <li>Scala</li>
                    <li>Python</li>
                  </ul>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant='body2'
                  color='textSecondary'
                  component='span'
                >
                  <ul>
                    <li>TensorFlow</li>
                    <li>Image Analysis</li>
                  </ul>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card
          style={{
            borderRadius: 0,
            boxShadow: '1px 1px 1px 1px rgba(52, 98, 108, .8)',
          }}
        >
          <CardContent style={{ paddingBottom: '0px' }}>
            <Typography variant='h5' component='h2'>
              Software
            </Typography>
            <Grid
              container
              direction='row'
              justify='flex-start'
              alignItems='center'
            >
              <Grid item>
                <Typography
                  variant='body2'
                  color='textSecondary'
                  component='span'
                >
                  <ul>
                    <li>TypeScript</li>
                    <li>React</li>
                  </ul>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant='body2'
                  color='textSecondary'
                  component='span'
                >
                  <ul>
                    <li>AWS</li>
                    <li>Azure</li>
                  </ul>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};

export default CoreSkills;
