import React from 'react';
import EmailIcon from '@material-ui/icons/Email';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { Grid, Card, CardContent, Typography } from '@material-ui/core';

const Contact = () => {
  return (
    <Card
      style={{
        height: '237px',
        borderRadius: 0,
        boxShadow: '1px 1px 1px 1px rgba(52, 98, 108, .8)',
      }}
    >
      <CardContent>
        <Grid
          container
          direction='column'
          justify='space-around'
          alignItems='flex-start'
          style={{ height: '205px' }}
        >
          <Grid item>
            <Typography>
              <b style={{ color: 'rgb(52, 98, 108)' }}>Name:</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body2' color='textSecondary' component='span'>
              <span>Konrad Makhool Gjertsson</span>
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <b style={{ color: 'rgb(52, 98, 108)' }}>Age:</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body2' color='textSecondary' component='span'>
              26
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <b style={{ color: 'rgb(52, 98, 108)' }}>Location:</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body2' color='textSecondary' component='span'>
              Stockholm, Sweden
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <b style={{ color: 'rgb(52, 98, 108)' }}>Contact:</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <a
                style={{
                  color: '#000000',
                  textDecoration: 'none',
                }}
                href={'mailto:konrad.gjertsson@protonmail.com'}
              >
                <EmailIcon />
              </a>
              <a
                style={{
                  color: '#000000',
                  textDecoration: 'none',
                }}
                href={'https://www.linkedin.com/in/konrad-gjertsson/'}
              >
                <LinkedInIcon />
              </a>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Contact;
