import React from 'react';

import { Card, CardContent } from '@material-ui/core';
import SchoolIcon from '@material-ui/icons/School';
import GavelIcon from '@material-ui/icons/Gavel';

interface Props {
  description: JSX.Element;
  isPublication: boolean;
}

const PublicationCard = ({ description, isPublication }: Props) => {
  return (
    <Card style={{ display: 'flex', borderRadius: 0, margin: '8px' }}>
      {isPublication ? (
        <SchoolIcon style={{ paddingLeft: '16px', paddingTop: '16px' }} />
      ) : (
        <GavelIcon style={{ paddingLeft: '16px', paddingTop: '16px' }} />
      )}
      <CardContent>{description}</CardContent>
    </Card>
  );
};

export default PublicationCard;
