import React from 'react';

import { Typography } from '@material-ui/core';

export interface Experience {
  timeRange: string;
  image: any;
  company: string;
  title: string;
  expanded: boolean;
  description: JSX.Element;
}

export const experiences: Array<Experience> = [
  {
    timeRange: 'Q4 2020 – Ongoing',
    image: require('../static/afry_square.png'),
    company: 'AFRY',
    title: 'Project Leader Consultant (AFRY)',
    expanded: false,
    description: (
      <Typography variant='body2' color='textSecondary' component='span'>
        AFRY is one of the largest engineering consultant companies in Europe
        with over 17000 employees. AFRY’s area of interest contains but is not
        limited to the areas industry and digital solutions, energy, management
        and infrastructure.
        <br />
        <br />
        One of the employee benefits at AFRY is access to a network of vacation
        cottages. This initiative aimed to revitalize the cottage booking
        platform where AFRY has decided to invest in modern web interface to
        improve the user experience with the cottage booking platform.
        <br />
        <br />
        My role as a project leader included among other things to formalizing
        the project by creating a project plan and a rough implementation
        timeline. I was also responsible for the customer relationship as well
        as the creation of an effective and agile work process for the
        development team using Scrum. My biggest challenge was to get the full
        picture of the project scope and customer expectations from the many
        involved parties, none of which knew all parts of the project.
        <br />
        <br />
        Competences:
        <ul>
          <li>Project Management</li>
          <li>Scrum</li>
          <li>Agile</li>
          <li>JIRA</li>
          <li>Confluence</li>
        </ul>
      </Typography>
    ),
  },
  {
    timeRange: 'Q3 2020 – Q3 2020',
    image: require('../static/afry_square.png'),
    company: 'AFRY',
    title: 'Data Engineer and Frontend Developer Consultant (AFRY)',
    expanded: false,
    description: (
      <Typography variant='body2' color='textSecondary' component='span'>
        AFRY is one of the largest engineering consultant companies in Europe
        with over 17000 employees. AFRY’s area of interest contains but is not
        limited to the areas industry and digital solutions, energy, management
        and infrastructure.
        <br />
        <br />
        One of AFRY’s existing clients in the garbage recycling industry was
        interested in investigating what AI, BI and Analytics could do to
        improve their business. As part of this initiative my role demanded
        close collaboration with other developers and involved setting up a
        fully automated data processing pipeline in the cloud as well as to
        create the user-facing interface.
        <br />
        <br />
        I fulfilled my tasks by creating an ETL process for automatic data
        processing, a graphical user interface and an API for communication
        between the interface, data pipeline and data science platform.
        <br />
        <br />
        Competences:
        <ul>
          <li>Amazon Web Services</li>
          <li>AWS GLUE</li>
          <li>AWS Amplify</li>
          <li>ETL</li>
          <li>React</li>
          <li>Apache Spark</li>
          <li>Scala</li>
          <li>TypeScript</li>
          <li>Serverless</li>
          <li>Node JS</li>
          <li>Express JS</li>
          <li>Git</li>
          <li>REST API</li>
        </ul>
      </Typography>
    ),
  },
  {
    timeRange: 'Q3 2019 – Q3 2020',
    image: require('../static/afry_square.png'),
    company: 'AFRY',
    title: 'Software Engineer Consultant (AFRY)',
    expanded: false,
    description: (
      <Typography variant='body2' color='textSecondary' component='span'>
        The client is one of the largest tech companies in the world and
        operates on multiple continents. The business has expanded to cover
        everything from hardware, software to IoT devices.
        <br />
        <br />
        One of the client’s applications includes petabytes of geospatial data.
        To improve the user experience and trust in the product the client
        launched an extensive data validation initiative with the aim of
        identifying discrepancies between the data model and the data itself.
        <br />
        <br />
        As a core part of this initiative, I used languages such as Scala, Java
        and Typescript for identification and correction of data errors as a
        backend developer for the international big data project. The position
        demanded the development of complex algorithms with tight constraints on
        both computation time and memory, executed in the cloud on platforms
        such as Spark and Hadoop.
        <br />
        <br />
        Competences:
        <ul>
          <li>Scala</li>
          <li>TypeScript</li>
          <li>Git</li>
          <li>Scrum</li>
          <li>Python</li>
          <li>Jenkins</li>
          <li>Algorithm Development</li>
          <li>Big Data</li>
        </ul>
      </Typography>
    ),
  },
  {
    timeRange: 'Q3 2019 – Ongoing',
    image: require('../static/background.jpg'),
    company: 'GjertssonChen AB',
    title: 'Co-founder and Chairman of the Board',
    expanded: false,
    description: (
      <Typography variant='body2' color='textSecondary' component='span'>
        Sweden has a plethora of public APIs and datasets, ranging from weather
        forecasts to live public transit tracking to general data about its
        citizens. However, in order to access the information hidden in these
        public APIs one must possess both expert knowledge and have access to
        specialized tools. The goal of GjertssonChen AB is to remove the
        thresholds between ordinary citizens and the data available to them by
        making the publicly available data available to the public.
        <br />
        <br />
        Statistiska Central Byrån (SCB) provides detailed geographical
        information about the Swedish population through their web API.
        GjertssonChen saw the opportunity to improve on the interface between
        the population and SCB’s api and decided to produce an interactive and
        easy to use web application where users can visualize statistics about
        the Swedish population. The finished product is called{' '}
        <a href='https://www.samstat.se'>SamStat</a>
        <br />
        <br />
        In order to reach this goal, we created a business plan, solution design
        and completed the implementation. The technical solution consisted of a
        cloud-based, cost-optimized and cross-platform architecture built on
        Google Firebase and Amazon Web Services. We developed a SPA written in
        React together with Firebase Functions and AWS storage services to
        achieve a completely serverless architecture.
        <br />
        <br />
        Competences:
        <ul>
          <li>Javascript</li>
          <li>Serverless Architecture</li>
          <li>React</li>
          <li>Git</li>
          <li>NodeJS</li>
          <li>Google Firebase</li>
          <li>Amazon Web Services</li>
          <li>HTML</li>
          <li>CSS</li>
          <li>Material Design</li>
        </ul>
      </Typography>
    ),
  },
  {
    timeRange: 'Q1 2019 – Ongoing',
    image: require('../static/maadwalk_square.png'),
    company: 'Maadwalk Games AB',
    title: 'Fullstack Developer',
    expanded: false,
    description: (
      <Typography variant='body2' color='textSecondary' component='span'>
        My passion for software development and desire to collaborate with other
        people urged him to get involved with my friends’ company Maadwalk Games
        AB. Maadwalk is a mobile game development company where the founders are
        a group of friends from KTH. Some of these also happen to my be
        childhood friends. I have no experience with game development but
        decided to contribute to the company by developing and maintaining the{' '}
        <a href='https://maadwalk.com'> company website</a>. The website is
        still active today and is updated continuously.
        <br />
        <br />
        Competences:
        <ul>
          <li>TypeScript</li>
          <li>Git</li>
          <li>React</li>
          <li>NodeJS</li>
          <li>Amazon Web Services</li>
          <li>Serverless Architecture</li>
        </ul>
      </Typography>
    ),
  },
  {
    timeRange: 'Q2 2018 – Q3 2019',
    image: require('../static/exini_square.png'),
    company: 'EXINI Diagnostics AB',
    title: 'Data Scientist - PyL',
    expanded: false,
    description: (
      <Typography variant='body2' color='textSecondary' component='span'>
        EXINI is a small research-oriented med-tech company in Lund focused on
        cloud-based AI solutions. EXINI had recently been purchased by the
        American pharmaceutical company Progenics Pharmaceuticals, which
        acquired EXINI in hopes of improving sales by coupling an AI decision
        support with their radioactive tracers for prostate cancer.
        <br />
        <br />
        The goal of the project was to produce an AI solution for automatic
        identification and standardized quantification of primary tumors and
        metastatic lymph nodes in early stage prostate cancer patients using
        Progenic’s radioactive substance PyL for whole-body co-registered PET/CT
        images. My role was to design, train and ship deep learning models for
        localization and segmentation of body-parts in CT coupled with
        traditional image analysis techniques for identification of the lesions
        in the co-registered PET image.
        <br />
        <br />
        In my role as ML Engineer I was responsible for the development of image
        analysis algorithms and deep learning models using TensorFlow and
        Pandas. I also developed an extensive platform for managing the large
        quantity of data used in the project.
        <br />
        <br />
        Competences:
        <ul>
          <li>Python</li>
          <li>Image Analysis</li>
          <li>JavaScript</li>
          <li>Atlassian (Jira, Bamboo)</li>
          <li>TensorFlow</li>
          <li>Scrum</li>
          <li>Keras</li>
          <li>Git</li>
          <li>Pandas</li>
        </ul>
      </Typography>
    ),
  },
  {
    timeRange: 'Q2 2017 – Q3 2018',
    image: require('../static/exini_square.png'),
    company: 'EXINI Diagnostics AB',
    title: 'Data Scientist - 1404',
    expanded: false,
    description: (
      <Typography variant='body2' color='textSecondary' component='span'>
        EXINI is a small research-oriented med-tech company in Lund focused on
        cloud-based AI solutions. EXINI had recently been purchased by the
        American pharmaceutical company Progenics Pharmaceuticals, which
        acquired EXINI in hopes of improving sales by coupling an AI decision
        support with their radioactive tracers for prostate cancer.
        <br />
        <br />
        The goal of the project was to produce an AI solution for automatic
        estimate of the gleason score of prostate cancer patients in
        co-registered SPECT/CT images of the pelvic region, where Progenics’
        radioactive substance known as 1404 was used. My role was design, train
        and ship deep learning models for localization and segmentation of
        body-parts in CT coupled with traditional image analysis techniques for
        quantification in the co-registered SPECT image.
        <br />
        <br />
        In my role as ML Engineer I was responsible for the development of image
        analysis algorithms and deep learning models using TensorFlow and
        Pandas. I also developed an extensive platform for managing the large
        quantity of data used in the project.
        <br />
        <br />
        Competences:
        <ul>
          <li>Python</li>
          <li>Image Analysis</li>
          <li>JavaScript</li>
          <li>Atlassian (Jira, Bamboo)</li>
          <li>TensorFlow</li>
          <li>Scrum</li>
          <li>Keras</li>
          <li>Git</li>
          <li>Pandas</li>
        </ul>
      </Typography>
    ),
  },
  {
    timeRange: '2012-09 - 2017-06',
    image: require('../static/lth_square.png'),
    company: 'Lund Faculty of Engineering, Lund University',
    title: 'Bachelor and Master Student',
    expanded: false,
    description: (
      <Typography variant='body2' color='textSecondary'>
        Master of Science in Engineering, Computer Science and Engineering.
        Partook in the european Erasmus exchange program and spent one year of
        my master at Université Libre de Bruxelles in Brussels, Belgium, where I
        studied theoretical artificial intelligence, statistics and machien
        learning. I performed my master thesis in collaboration with EXINI
        Diagnostics AB and the department of mathematics at LTH.
        <br />
        <br />
        EXINI is a small research-oriented med-tech company in Lund focused on
        cloud-based AI solutions.
        <br />
        <br />
        EXINI has been working with AI solutions in the cloud since 1999. The
        company had a well-established routine with working with traditional
        machine learning methods such as shallow neural networks. In 2017 the
        company was at a crossroads on whether to continue with what they knew
        or try the new and exciting technology known as deep learning.
        <br />
        <br />
        My master thesis was one step in the research done by EXINI on which
        technologies to use for future project. I successfully proved that deep
        learning worked as well if not better than the traditional models, which
        was part in the company’s decision to proceed with deep learning in
        future projects.
      </Typography>
    ),
  },
];
