import React from 'react';

import { Card, Typography, Grid } from '@material-ui/core';

import ExperienceCard from './ExperienceCard';

const storyCardxs = 10;
const storyCardsm = 10;
const storyCardmd = 10;
const storyCardlg = 10;
const storyCardxl = 10;

const MyStory = () => {
  return (
    <Card elevation={0}>
      <Grid
        container
        justify='flex-start'
        alignItems='center'
        direction='column'
        style={{ height: '100%' }}
      >
        <Grid
          item
          xs={storyCardxs}
          sm={storyCardsm}
          md={storyCardmd}
          lg={storyCardlg}
          xl={storyCardxl}
          style={{ width: '100%' }}
        >
          <ExperienceCard
            image={require('../../static/afry_square.png')}
            company={'AFRY'}
            title={'Project Leader Consultant (AFRY)'}
            description={
              <Typography
                variant='body2'
                color='textSecondary'
                component='span'
              >
                Project Leader for a small team in an internal project at AFRY
                with the aim of creating a platform for renting of the company's
                vacation cottages.
                <br />
                <br />
                Competences:
                <ul>
                  <li>Communication</li>
                  <li>Representation</li>
                  <li>Planning</li>
                  <li>Ownership</li>
                </ul>
              </Typography>
            }
          />
        </Grid>
        <Grid
          item
          xs={storyCardxs}
          sm={storyCardsm}
          md={storyCardmd}
          lg={storyCardlg}
          xl={storyCardxl}
          style={{ width: '100%' }}
        >
          <ExperienceCard
            image={require('../../static/afry_square.png')}
            company={'Undisclosed'}
            title={'Software Developer Consultant (AFRY)'}
            description={
              <Typography
                variant='body2'
                color='textSecondary'
                component='span'
              >
                Used languages such as Scala, Java and Typescript for
                identification and correction of data errors as a backend
                developer for an international big data project at one of the
                largest software development firms in the world. The position
                demanded the development of complex algorithms with tight
                constraints on both computation time and memory, executed in the
                cloud on platforms such as Spark and Hadoop. The assignment also
                included the development and use of REST APIs for communication
                and data transportation between internal services. Jenkins was
                used to manage the CI/CD processes.
                <br />
                <br />
                Competences:
                <ul>
                  <li>Scala</li>
                  <li>TypeScript</li>
                  <li>Python</li>
                  <li>Algorithm Development</li>
                  <li>Big Data</li>
                  <li>Git</li>
                  <li>Scrum</li>
                  <li>Jenkins</li>
                </ul>
              </Typography>
            }
          />
        </Grid>
        <Grid
          item
          xs={storyCardxs}
          sm={storyCardsm}
          md={storyCardmd}
          lg={storyCardlg}
          xl={storyCardxl}
          style={{ width: '100%' }}
        >
          <ExperienceCard
            image={require('../../static/background.jpg')}
            company={'GjertssonChen AB'}
            title={'Co-founder and Chairman of the Board'}
            description={
              <Typography
                variant='body2'
                color='textSecondary'
                component='span'
              >
                Sweden has a plethora of public APIs and datasets, but in order
                to access these one must posses export knowledge and have access
                to specialized tools. We have the tools and skills necessary and
                decided to make the publicly available data available to the
                public.
                <br />
                <br />
                We have devleoped a service called{' '}
                <a href='https://www.samstat.se'>Samstat</a>, where geospatial
                data of the Swedish population and services are presented in an
                appealing and intuitive way. Samstat, built using Javascript and
                React, lives in a completely serverless architecture powered by
                Google Firebase and Amazon Web Services.
                <br />
                <br />
                Competences:
                <ul>
                  <li>Javascript</li>
                  <li>React</li>
                  <li>NodeJS</li>
                  <li>Google Firebase</li>
                  <li>Amazon Web Services</li>
                  <li>Serverless Architecture</li>
                  <li>Git</li>
                </ul>
              </Typography>
            }
          />
        </Grid>
        <Grid
          item
          xs={storyCardxs}
          sm={storyCardsm}
          md={storyCardmd}
          lg={storyCardlg}
          xl={storyCardxl}
          style={{ width: '100%' }}
        >
          <ExperienceCard
            image={require('../../static/maadwalk_square.png')}
            company={'Maadwalk Games AB'}
            title={'Web Developer'}
            description={
              <Typography
                variant='body2'
                color='textSecondary'
                component='span'
              >
                When some good friends of mine got together and founded the
                mobile game development company Maadwalk Games AB I asked myself
                how I could contribute. I knew nothing about game development
                but had read up on web development and thought that a website
                for their company would prove a suitble initial project in my
                web development career. In early 2019 I created they the
                Maadwalk <a href='https://maadwalk.com'> company page</a>, and
                continue to update, improve and maintain the website to this
                day.
                <br />
                <br />
                Competences:
                <ul>
                  <li>TypeScript</li>
                  <li>React</li>
                  <li>NodeJS</li>
                  <li>Amazon Web Services</li>
                  <li>Serverless Architecture</li>
                  <li>Git</li>
                </ul>
              </Typography>
            }
          />
        </Grid>
        <Grid
          item
          xs={storyCardxs}
          sm={storyCardsm}
          md={storyCardmd}
          lg={storyCardlg}
          xl={storyCardxl}
          style={{ width: '100%' }}
        >
          <ExperienceCard
            image={require('../../static/exini_square.png')}
            company={'EXINI Diagnostics AB'}
            title={'Data Scientist'}
            description={
              <Typography
                variant='body2'
                color='textSecondary'
                component='span'
              >
                Responsible for the development of image analysis algorithms and
                deep learning models using TensorFlow and Pandas. The aim was to
                process medical hybrid-images and host the Python-based
                infrastructure we built in the cloud using infrastructure such
                as AWS and GCP. Primary tasks were segmentation, localisation
                and classification in co-registered PET/CT images. The deep
                learning algorithms often had to be coupled with traditional
                image analysis techniques to achieve optimal performance. The
                development was performed using the Atlassian suite, where e.g
                Jira was used to manage the agile development process and Bamboo
                used to manage the CI and CD.
                <br />
                <br />
                The entire company consisted of around a dozen people. In such
                an environment it is natural to work across the stack, and not
                always with ones core responsibilities. The role required
                everything from JavaScript development for cloud-based
                visualization tools to work with the dockerized microservice
                production environment to QA and testing.
                <br />
                <br />
                Competences:
                <ul>
                  <li>Python</li>
                  <li>JavaScript</li>
                  <li>TensorFlow</li>
                  <li>Keras</li>
                  <li>Pandas</li>
                  <li>Image Analysis</li>
                  <li>Med-tech</li>
                  <li>Atlassian (Jira, Bamboo)</li>
                  <li>Scrum</li>
                  <li>Git</li>
                </ul>
              </Typography>
            }
          />
        </Grid>
        <Grid
          item
          xs={storyCardxs}
          sm={storyCardsm}
          md={storyCardmd}
          lg={storyCardlg}
          xl={storyCardxl}
          style={{ width: '100%' }}
        >
          <ExperienceCard
            image={require('../../static/lth_square.png')}
            company={'Lund Faculty of Engineering, Lund University'}
            title={'Bachelor and Master Student'}
            description={
              <Typography variant='body2' color='textSecondary'>
                Master of Science in Engineering, Computer Science and
                Engineering.
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default MyStory;
