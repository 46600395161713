import React from 'react';

import {
  Card,
  CardMedia,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';

const Cerfication = () => {
  return (
    <Card style={{ margin: '10px', minHeight: '100vh' }} elevation={0}>
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='center'
        style={{ height: '100vh' }}
      >
        <Grid item>
          <Card style={{ textAlign: 'center' }} elevation={0}>
            <CardContent>
              <Typography
                component='h5'
                variant='h2'
                style={{ color: 'rgb(52, 98, 108)' }}
              >
                Certificates
              </Typography>
            </CardContent>
          </Card>
          <Card
            style={{
              display: 'flex',
              width: '100%',
              borderRadius: 0,
              margin: '10px',
            }}
          >
            <CardMedia
              image={require('../static/AWS-CloudPractitioner.png')}
              style={{
                height: '100px',
                width: '100px',
                margin: '16px',
                marginRight: '0px',
              }}
            />
            <CardContent
              style={{
                flex: '1 0 auto',
                width: 'calc(100% - 232px)',
              }}
            >
              <Typography component='h5' variant='h5'>
                AWS Certified Cloud Practitioner
              </Typography>
              <Typography variant='subtitle1' color='textSecondary'>
                Amazon Web Services (AWS)
              </Typography>
              <Typography
                variant='body2'
                color='textSecondary'
                component='span'
              >
                <a href='https://www.youracclaim.com/badges/98fecd5c-cbbe-419b-b844-ca035f6ace6b?source=linked_in_profile'>
                  2020-10 - 2023-10
                </a>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Cerfication;
