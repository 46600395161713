import React from 'react';
import { Card, Grid, Typography } from '@material-ui/core';

import { tileData } from '../content/Passion';

const MyPassions = () => {
  return (
    <Card style={{ minHeight: '100vh', textAlign: 'center' }} elevation={0}>
      <Grid
        container
        direction='row'
        alignItems='center'
        justify='center'
        style={{ minHeight: '100vh' }}
      >
        <Grid item>
          <Card style={{ width: '100%' }} elevation={0}>
            <Typography variant='h2' style={{ color: 'rgb(52, 98, 108)' }}>
              My Passion
            </Typography>
            <Typography variant='body2' color='textSecondary' component='span'>
              “It is not the mountain we conquer, but ourselves. - George
              Mallory
            </Typography>
            <Grid
              container
              direction='row'
              justify='center'
              alignItems='center'
            >
              {tileData.map((tile, index) => (
                <Grid item key={index}>
                  <img
                    src={tile.img}
                    alt={tile.title}
                    style={{
                      height: '250px',
                      width: '250px',
                      paddingLeft: '2px',
                      paddingRight: '2px',
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Card>
  );
};

export default MyPassions;
