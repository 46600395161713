import React from 'react';
import { Card, CardMedia, Typography, Grid } from '@material-ui/core';

const GreetingsCard = () => {
  return (
    <Card>
      <CardMedia
        image={require('../static/mountain_02_square.png')}
        style={{ height: '100vh' }}
      >
        <Grid
          container
          justify='center'
          alignItems='center'
          style={{ height: '100%' }}
        >
          <Grid item>
            <Typography
              style={{ color: 'white', textAlign: 'center' }}
              variant='h2'
            >
              Konrad Makhool Gjertsson
            </Typography>
          </Grid>
        </Grid>
      </CardMedia>
    </Card>
  );
};

export default GreetingsCard;
