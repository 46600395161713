import React from 'react';

import { Card, Grid, Typography } from '@material-ui/core';

import AboutSummary from './AboutSummary';
import PictureAndCoreSummary from './PictureAndCoreSummary';

const Summary = () => {
  return (
    <Card style={{ flexGrow: 1 }} elevation={0}>
      <Grid
        container
        direction='column'
        justify='center'
        alignItems='center'
        style={{ minHeight: '100vh' }}
      >
        <Grid item>
          <Card elevation={0}>
            <Typography
              style={{ textAlign: 'center', color: 'rgb(52, 98, 108)' }}
              variant='h2'
            >
              Profile
            </Typography>
          </Card>
        </Grid>
        <PictureAndCoreSummary />
        <AboutSummary />
      </Grid>
    </Card>
  );
};

export default Summary;
