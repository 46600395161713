import React from 'react';

import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Hidden,
} from '@material-ui/core';

interface Props {
  image: any;
  company: string;
  title: string;
  description: JSX.Element;
}

const ExperienceCard = ({ image, company, title, description }: Props) => {
  const SmallScreenCard = () => {
    return (
      <Card style={{ width: '100%', borderRadius: 0 }}>
        <CardMedia image={image} style={{ height: '200px', width: '100%' }} />
        <CardContent
          style={{
            width: 'calc(100% - 32px)',
          }}
        >
          <Typography component='h5' variant='h5'>
            {company}
          </Typography>
          <Typography variant='subtitle1' color='textSecondary'>
            {title}
          </Typography>
          {description}
        </CardContent>
      </Card>
    );
  };

  const LargeScreenCard = () => {
    return (
      <Card style={{ display: 'flex', width: '600px', borderRadius: 0 }}>
        <CardMedia
          image={image}
          style={{
            height: '200px',
            width: '200px',
            margin: '16px',
            marginRight: '0px',
          }}
        />
        <CardContent
          style={{
            width: 'calc(100% - 232px)',
          }}
        >
          <Typography component='h5' variant='h5'>
            {company}
          </Typography>
          <Typography variant='subtitle1' color='textSecondary'>
            {title}
          </Typography>
          {description}
        </CardContent>
      </Card>
    );
  };

  return (
    <div>
      <Hidden smUp>
        <SmallScreenCard />
      </Hidden>
      <Hidden xsDown>
        <LargeScreenCard />
      </Hidden>
    </div>
  );
};

export default ExperienceCard;
