import React from 'react';

import { Grid, Card, Typography, Hidden } from '@material-ui/core';

import GreetingsCard from './components/GreetingsCard';
import Summary from './components/Summary/Summary';
import MyTimeline from './components/Experience/MyTimeline';
import MyStory from './components/Experience/MyStory';
import MyPassions from './components/MyPassions';
import Certification from './components/Certification';
import Publications from './components/Publications';

const App = () => {
  return (
    <div style={{ flexGrow: 1, margin: '0px' }}>
      <Grid
        container
        direction='column'
        alignItems='center'
        justify='flex-start'
        style={{ width: '100%', margin: '0px' }}
      >
        <Grid item style={{ width: '100%' }}>
          <GreetingsCard />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <Summary />
        </Grid>
        <Grid item>
          <Card elevation={0}>
            <Typography
              style={{ textAlign: 'center', color: 'rgb(52, 98, 108)' }}
              variant='h2'
            >
              My Story
            </Typography>
          </Card>
        </Grid>
        <Hidden xsDown>
          <Grid item>
            <MyTimeline />
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item style={{ width: '100%' }}>
            <MyStory />
          </Grid>
        </Hidden>
        <Grid item style={{ width: '100%' }}>
          <Certification />
        </Grid>
        <Grid item style={{ width: '100%', marginBottom: '16px' }}>
          <Publications />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <MyPassions />
        </Grid>
      </Grid>
    </div>
  );
};

export default App;
