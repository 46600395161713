import React from 'react';
import { Grid } from '@material-ui/core';

import CoreSkills from './CoreSkills';
import Contact from './Contact';

const PictureAndCoreSummary = () => {
  return (
    <Grid
      item
      container
      direction='row'
      justify='center'
      alignItems='center'
      xs={12}
      sm={8}
      md={12}
      lg={10}
      xl={10}
    >
      <Grid item>
        <CoreSkills />
      </Grid>
      <Grid item style={{ padding: '16px' }}>
        <img
          alt='profile'
          src={require('../../static/profile_picture.png')}
          style={{
            height: '300px',
            width: '300px',
            borderRadius: 180,
          }}
        />
      </Grid>
      <Grid item style={{ padding: '16px', width: '320px' }}>
        <Contact />
      </Grid>
    </Grid>
  );
};

export default PictureAndCoreSummary;
