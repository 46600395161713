import React from 'react';
import { Grid, Card, Typography, CardContent } from '@material-ui/core';

import PublicationCard from './PublicationCard';

const xsPublication = 10;
const smPublication = 5;
const mdPublication = 5;
const lgPublication = 5;
const xlPublication = 5;

const Publications = () => {
  return (
    <Card elevation={0}>
      <Grid container direction='column' justify='center' alignItems='center'>
        <Grid
          item
          xs={xsPublication}
          sm={smPublication}
          md={mdPublication}
          lg={lgPublication}
          xl={xlPublication}
        >
          <Card elevation={0}>
            <CardContent>
              <Typography variant='h2' style={{ color: 'rgb(52, 98, 108)' }}>
                Publications
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={xsPublication}
          sm={smPublication}
          md={mdPublication}
          lg={lgPublication}
          xl={xlPublication}
        >
          <PublicationCard
            description={
              <Typography>
                Richter, J. F. A., Johnsson, K. E. M., <b>Gjertsson, E. K.</b>,
                & Anand, A. U. (2020). U.S. Patent Application No. 16/734,599.
              </Typography>
            }
            isPublication={false}
          />
        </Grid>
        <Grid
          item
          xs={xsPublication}
          sm={smPublication}
          md={mdPublication}
          lg={lgPublication}
          xl={xlPublication}
        >
          <PublicationCard
            description={
              <Typography>
                Sjöstrand, K. V., Richter, J. F. A., Johnsson, K. E. M., &{' '}
                <b>Gjertsson, E. K.</b> (2019). U.S. Patent Application No.
                16/003,006.
              </Typography>
            }
            isPublication={false}
          />
        </Grid>
        <Grid
          item
          xs={xsPublication}
          sm={smPublication}
          md={mdPublication}
          lg={lgPublication}
          xl={xlPublication}
        >
          <PublicationCard
            description={
              <Typography>
                <b>Gjertsson, K.</b>, Johnsson, K., Richter, J., Sjöstrand, K.,
                Edenbrandt, L., & Anand, A. (2019). A Novel Automated Deep
                Learning Algorithm for Segmentation of the Skeleton in Low-Dose
                CT for [(18) F] DCFPyL PET/CT Hybrid Imaging in Patients with
                Metastatic Prostate Cancer.
              </Typography>
            }
            isPublication={true}
          />
        </Grid>
        <Grid
          item
          xs={xsPublication}
          sm={smPublication}
          md={mdPublication}
          lg={lgPublication}
          xl={xlPublication}
        >
          <PublicationCard
            description={
              <Typography>
                Sjostrand, K., Edenbrandt, L., Stambler, N., Opanowski, A.,
                Richter, J., <b>Gjertsson, K.</b>, ... & Anand, A. (2019).
                Automated Assessment of Prostatic PSMA Expression in SPECT/CT
                using Deep Convolutional Neural Networks-A Prospectively Planned
                Retrospective Analysis of Phase 3 Study MIP-1404-3301. Journal
                of Nuclear Medicine, 60(supplement 1), 401-401.
              </Typography>
            }
            isPublication={true}
          />
        </Grid>
        <Grid
          item
          xs={xsPublication}
          sm={smPublication}
          md={mdPublication}
          lg={lgPublication}
          xl={xlPublication}
        >
          <PublicationCard
            description={
              <Typography>
                Pouliot, F., Sjöstrand, K., Stambler, N., Richter, J.,
                <b>Gjertsson, K.</b>, Johnsson, K., ... & Anand, A. (2019).
                Prospective evaluation of a Novel Deep Learning Algorithm
                (PSMA-AI) in the assessment of 99mTc-MIP-1404 SPECT/CT in
                patients with low or intermediate risk prostate cancer.
              </Typography>
            }
            isPublication={true}
          />
        </Grid>
        <Grid
          item
          xs={xsPublication}
          sm={smPublication}
          md={mdPublication}
          lg={lgPublication}
          xl={xlPublication}
        >
          <PublicationCard
            description={
              <Typography>
                Sjostrand, K., Anand, A., Richter, J., Johnsson, K.,{' '}
                <b>Gjertsson, K.</b>, Edenbrandt, L., & Wong, V. (2018).
                Automated detection and quantification of prostatic PSMA uptake
                in SPECT/CT using a deep learning algorithm for segmentation of
                pelvic anatomy. Journal of Nuclear Medicine, 59(supplement 1),
                30-30.
              </Typography>
            }
            isPublication={true}
          />
        </Grid>

        <Grid
          item
          xs={xsPublication}
          sm={smPublication}
          md={mdPublication}
          lg={lgPublication}
          xl={xlPublication}
        >
          <PublicationCard
            description={
              <Typography>
                <b>Gjertsson, K.</b> (2017). Segmentation in Skeletal
                Scintigraphy Images using Convolutional Neural Networks.
                Master's Theses in Mathematical Sciences.
              </Typography>
            }
            isPublication={true}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Publications;
