export const tileData = [
  {
    img: require('../static/mountain_01_square.png'),
    title: 'background',
  },
  {
    img: require('../static/mountain_02_square.png'),
    title: 'background',
  },
  {
    img: require('../static/mountain_03_square.png'),
    title: 'background',
  },
  {
    img: require('../static/mountain_04_square.png'),
    title: 'background',
  },
  {
    img: require('../static/mountain_05_square.png'),
    title: 'background',
  },
  {
    img: require('../static/mountain_06_square.png'),
    title: 'background',
  },
  {
    img: require('../static/mountain_07_square.png'),
    title: 'background',
    xs: 10,
  },
  {
    img: require('../static/mountain_08_square.png'),
    title: 'background',
  },
  {
    img: require('../static/mountain_09_square.png'),
    title: 'background',
  },
];
