import React from 'react';

import { Card, CardContent, CardMedia, Typography } from '@material-ui/core';

interface Props {
  image: any;
  company: string;
  title: string;
}
const ExperienceCardPreview = ({ image, company, title }: Props) => {
  return (
    <Card style={{ display: 'flex', width: '100%', borderRadius: 0 }}>
      <CardMedia
        image={image}
        style={{
          height: '100px',
          width: '100px',
          margin: '16px',
          marginRight: '0px',
        }}
      />
      <CardContent
        style={{
          flex: '1 0 auto',
          width: 'calc(100% - 232px)',
        }}
      >
        <Typography component='h5' variant='h5'>
          {company}
        </Typography>
        <Typography variant='subtitle1' color='textSecondary'>
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ExperienceCardPreview;
