import React from 'react';

import { Card, Grid, CardContent, Typography, Hidden } from '@material-ui/core';

const AboutSummary = () => {
  const MySummary = () => {
    return (
      <Typography
        variant='body2'
        color='textSecondary'
        style={{ textAlign: 'justify' }}
      >
        I am a software engineer and data scientist with over three years of
        professional experience working with cloud-based data-driven
        applications. My key skills include software development, machine
        learning and data processing on platforms such as AWS using Scala,
        TypeScript and Python. With assignments from both the largest tech
        companies in the world as well as from small start-ups, I have gained
        valuable insights in teamwork, communication, and humility.
        <br />
        <br />I believes that the key to good teamwork is humility. To
        understand and respect that there will always be someone who will be
        more senior, and someone more junior, and show humility in front of
        both. I has always been ready to shoulder responsibilities when provided
        the opportunities, be it a key part in a research publication or
        communication with stakeholders. Apart from the above I am an
        enthusiastic climber, a coffee addict as well as a hobby entrepreneur.
      </Typography>
    );
  };

  return (
    <div>
      <Hidden smDown>
        <Grid item>
          <Card elevation={0} style={{ minWidth: '320px', maxWidth: '972px' }}>
            <CardContent>
              <MySummary />
            </CardContent>
          </Card>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid item>
          <Card elevation={0} style={{ width: '320px' }}>
            <CardContent>
              <MySummary />
            </CardContent>
          </Card>
        </Grid>
      </Hidden>
    </div>
  );
};

export default AboutSummary;
