import React, { useState } from 'react';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from '@material-ui/lab';
import { Typography } from '@material-ui/core';

import ExperienceCard from './ExperienceCard';
import { experiences, Experience } from '../../content/Experience';
import ExperienceCardPreview from './ExperienceCardPreview';

const MyTimeline = () => {
  const [timelineExperiences, setTimelineExperiences] = useState<
    Array<Experience>
  >(experiences);

  return (
    <Timeline align='alternate'>
      {timelineExperiences.map((experience, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent>
            <Typography variant='body2' color='textSecondary'>
              {experience.timeRange}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent style={{ textAlign: 'left' }}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                const newTimeLineExperiences = timelineExperiences.map(
                  (innerExperience, innerIndex) => {
                    if (index === innerIndex) {
                      return {
                        timeRange: innerExperience.timeRange,
                        image: innerExperience.image,
                        company: innerExperience.company,
                        title: innerExperience.title,
                        expanded: !innerExperience.expanded,
                        description: innerExperience.description,
                      };
                    } else {
                      return innerExperience;
                    }
                  }
                );

                setTimelineExperiences(newTimeLineExperiences);
              }}
            >
              {experience.expanded ? (
                <ExperienceCard
                  image={experience.image}
                  company={experience.company}
                  title={experience.title}
                  description={experience.description}
                />
              ) : (
                <ExperienceCardPreview
                  image={experience.image}
                  company={experience.company}
                  title={experience.title}
                />
              )}
            </div>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default MyTimeline;
